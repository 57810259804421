import { colors, colorTokens, type Skin } from '@orus.eu/pharaoh'
import { fontweight } from '@orus.eu/pharaoh/src/foundation/font-weight-tokens'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import DougsHorizontalLogo from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.svg'

import { css } from '@emotion/react'
import Eina03_BoldUrl from './fonts/Eina03-Bold.ttf?url'
import Eina03_SemiBoldUrl from './fonts/Eina03-SemiBold.ttf?url'
import OpenSans_BoldUrl from './fonts/OpenSans-Bold.ttf?url'
import OpenSans_RegularUrl from './fonts/OpenSans-Regular.ttf?url'
import OpenSans_SemiBoldUrl from './fonts/OpenSans-SemiBold.ttf?url'

const Eina03_BoldFamily = 'Eina03-Bold'
const Eina03_SemiBoldFamily = 'Eina03-SemiBold'
const OpenSans_RegularFamily = 'OpenSans-Regular'
const OpenSans_SemiBoldFamily = 'OpenSans-SemiBold'
const OpenSans_BoldFamily = 'OpenSans-Bold'

export const dougsSkin: Skin = {
  avatarUrl,
  quoteCardHelperBackgroundColor: colorTokens['color-bg-decorative-1'],
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  mainActivitySelector: {
    borderColor: colorTokens['color-stroke-base'],
    backgroundColor: colorTokens['color-bg-base-normal'],
  },
  horizontalLogo: {
    aspectRatio: 71 / 24,
    ImageComponent: DougsHorizontalLogo,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colors.black,
    backgroundColor: colors.sky.dark,
  },
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: Eina03_BoldFamily,
    fontFamilyUrl: Eina03_BoldUrl,
  },
  additionalStylesheets: [Eina03_BoldUrl, Eina03_SemiBoldUrl],
  additionalStyles: css`
    @font-face {
      font-family: ${Eina03_BoldFamily};
      src: url(${Eina03_BoldUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ${Eina03_SemiBoldFamily};
      src: url(${Eina03_SemiBoldUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ${OpenSans_RegularFamily};
      src: url(${OpenSans_RegularUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ${OpenSans_SemiBoldFamily};
      src: url(${OpenSans_SemiBoldUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ${OpenSans_BoldFamily};
      src: url(${OpenSans_BoldUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `,
  cssVariables: {
    '--skin-font': OpenSans_RegularFamily,
    '--skin-font-title': Eina03_SemiBoldFamily,
    '--skin-font-button': OpenSans_SemiBoldFamily,
    '--skin-font-subtitle1': OpenSans_RegularFamily,
    '--skin-font-subtitle2': OpenSans_RegularFamily,
    '--skin-font-body1': OpenSans_RegularFamily,
    '--skin-font-weight-title': fontweight.regular,
    '--skin-font-weight-subtitle1': fontweight.regular,
    '--skin-font-weight-subtitle2': fontweight.regular,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.regular,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': 'var(--Dougs_Blue-100)',
    '--skin-blue-200': 'var(--Dougs_Blue-200)',
    '--skin-blue-300': 'var(--Dougs_Blue-300)',
    '--skin-blue-400': 'var(--Dougs_Blue-400)',
    '--skin-blue-500': 'var(--Dougs_Blue-500)',
    '--skin-blue-600': 'var(--Dougs_Blue-600)',
    '--skin-blue-700': 'var(--Blue-700)',
    '--skin-blue-800': 'var(--Blue-800)',
    '--skin-blue-900': 'var(--Blue-900)',
    '--skin-gray-100': 'var(--Gray-100)',
    '--skin-gray-200': 'var(--Gray-200)',
    '--skin-gray-300': 'var(--Gray-300)',
    '--skin-gray-400': 'var(--Gray-400)',
    '--skin-gray-500': 'var(--Gray-500)',
    '--skin-gray-600': 'var(--Gray-600)',
    '--skin-gray-700': 'var(--Gray-700)',
    '--skin-gray-800': 'var(--Gray-800)',
    '--skin-gray-900': 'var(--Gray-900)',
    '--skin-green-100': 'var(--Green-100)',
    '--skin-green-200': 'var(--Green-200)',
    '--skin-green-300': 'var(--Green-300)',
    '--skin-green-400': 'var(--Green-400)',
    '--skin-green-500': 'var(--Green-500)',
    '--skin-green-600': 'var(--Green-600)',
    '--skin-green-700': 'var(--Green-700)',
    '--skin-green-800': 'var(--Green-800)',
    '--skin-green-900': 'var(--Green-900)',
    '--skin-yellow-100': 'var(--Yellow-100)',
    '--skin-yellow-200': 'var(--Yellow-200)',
    '--skin-yellow-300': 'var(--Yellow-300)',
    '--skin-yellow-400': 'var(--Yellow-400)',
    '--skin-yellow-500': 'var(--Yellow-500)',
    '--skin-yellow-600': 'var(--Yellow-600)',
    '--skin-yellow-700': 'var(--Yellow-700)',
    '--skin-yellow-800': 'var(--Yellow-800)',
    '--skin-yellow-900': 'var(--Yellow-900)',
    '--skin-orange-100': 'var(--Orange-100)',
    '--skin-orange-200': 'var(--Orange-200)',
    '--skin-orange-300': 'var(--Orange-300)',
    '--skin-orange-400': 'var(--Orange-400)',
    '--skin-orange-500': 'var(--Orange-500)',
    '--skin-orange-600': 'var(--Orange-600)',
    '--skin-orange-700': 'var(--Orange-700)',
    '--skin-orange-800': 'var(--Orange-800)',
    '--skin-orange-900': 'var(--Orange-900)',
    '--skin-red-100': 'var(--Red-100)',
    '--skin-red-200': 'var(--Red-200)',
    '--skin-red-300': 'var(--Red-300)',
    '--skin-red-400': 'var(--Red-400)',
    '--skin-red-500': 'var(--Red-500)',
    '--skin-red-600': 'var(--Red-600)',
    '--skin-red-700': 'var(--Red-700)',
    '--skin-red-800': 'var(--Red-800)',
    '--skin-red-900': 'var(--Red-900)',
    '--skin-white': 'var(--White)',
    '--skin-black': 'var(--Black)',
    '--skin-linen': 'var(--Linen)',
    '--skin-sky-dark': 'var(--Dougs_Blue-100)',
    '--skin-sky-light': 'var(--Dougs_Blue-100)',
    '--skin-sky-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Dougs_Blue-100) 0%, var(--Dougs_Blue-100) 100%)',
    '--skin-periwinkle-dark': 'var(--Dougs_Blue-100)',
    '--skin-periwinkle-light': 'var(--Dougs_Blue-100)',
    '--skin-periwinkle-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Dougs_Blue-100) 0%, var(--Dougs_Blue-100) 100%)',
    '--skin-mindaro-dark': 'var(--Dougs_Blue-100)',
    '--skin-mindaro-light': 'var(--Dougs_Blue-100)',
    '--skin-mindaro-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Dougs_Blue-100) 0%, var(--Dougs_Blue-100) 100%)',
    '--skin-jasmine-dark': 'var(--Dougs_Blue-100)',
    '--skin-jasmine-light': 'var(--Dougs_Blue-100)',
    '--skin-jasmine-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Dougs_Blue-100) 0%, var(--Dougs_Blue-100) 100%)',
    '--skin-peach-dark': 'var(--Dougs_Blue-100)',
    '--skin-peach-light': 'var(--Dougs_Blue-100)',
    '--skin-peach-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Dougs_Blue-100) 0%, var(--Dougs_Blue-100) 100%)',
  },
}
